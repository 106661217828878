import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";

import "./index.css";
import "../../static/css/animate.css";

import { toggleLoader} from "../../store/actions/Auth";
import { Link, withRouter } from 'react-router-dom';
const metamask1 = "/images/metamask1.png";
const backarrow = "/images/back-arrow.png";


class ConnectingWallet extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <div className="wrapper"  >
        <section class="form-main sign-up">
          <div class="container">
            <div class="inner text-center">
              <Link class="back" to="/connectwallet">
                <img class="mb-3" src={backarrow} />
              </Link>

              <h1>Connect your wallet</h1>

              <p class="mb-4">Please select your wallet to login</p>
              
              <h2 class="mb-3">Metamask</h2>

              <img class="mb-3" src={metamask1} />

              <h2 class="mb-4">Awaiting Connection</h2>

              <p class="mb-4">Accept connection request in Metamask wallet</p>

              <p><Link to="">Don't have Metamask wallet?</Link></p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = { toggleLoader};

const mapStateToProps = ({ Auth }) => {
  let { } = Auth;
  return { };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConnectingWallet));
