// import Sockets from './Sockets';
import { all, takeEvery } from 'redux-saga/effects';
import { login,setMetaData,getUserMetaData,getNonce,getKycStatus,kycSubmit} from './Auth';

export default function* rootSaga() {
  yield all([
    yield takeEvery('LOGIN', login),
    yield takeEvery('GET_NONCE', getNonce),
    yield takeEvery('SET_METADATA', setMetaData),
    yield takeEvery('GET_USER_METADATA', getUserMetaData),
    yield takeEvery('GET_KYC_STATUS', getKycStatus),
    yield takeEvery('KYC_SUBMIT', kycSubmit),
  ]);
}