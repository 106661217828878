import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Veriff } from '@veriff/js-sdk';
import $ from 'jquery';

import "./index.css";
import "../../static/css/animate.css";

import { web3 } from "../../store/web3";
import {connectMetamask} from "../../store/walletConnect"
import { networkId, message, network, networkType} from "../../store/config";
import { loginLoader,logout, setAddress, setNetwork, getNonce,getKycStatus,kycSubmit, login} from "../../store/actions/Auth";
import { Link, withRouter } from 'react-router-dom';

const metamaskicon = "/images/metamask-icon.png";

function SignIn(props){

  useEffect(()=>{
    props.loginLoader({ status: true, message: "Verifying kyc..." });
    handleWalletChanges();
  },[]);
  
  useEffect(()=>{
    if (props.kycStatus == false && props.publicAddress) {
      // if (props.kycStatus == false && props.publicAddress && isWhitelist == true) {
      veriffStart();
      $(".veriff-container").prepend(`<label class="veriff-label" id="veriff-label-given-email" htmlfor="givenEmail">Email</label><input type="email" class="veriff-text" id="veriff-given-email" name="givenEmail" required="" placeholder="Email">`);
      var veriffVendorDataInput = document.getElementById("veriff-vendor-data");
      // Check if the element exists before attempting to set its value
      if (veriffVendorDataInput) {
        // Set the value of the input field
        veriffVendorDataInput.value = props.publicAddress.toLowerCase();
      }
    }
    else if (props.kycStatus == "expired" && props.publicAddress) {
      // else if (props.kycStatus == "expired" && props.publicAddress && isWhitelist == true) {
      veriffStart();
      $(".veriff-container").prepend(`<label class="veriff-label" id="veriff-label-given-email" htmlfor="givenEmail">Email</label><input type="email" class="veriff-text" id="veriff-given-email" name="givenEmail" required="" placeholder="Email">`);
      var veriffVendorDataInput = document.getElementById("veriff-vendor-data");
      // Check if the element exists before attempting to set its value
      if (veriffVendorDataInput) {
        // Set the value of the input field
        veriffVendorDataInput.value = props.publicAddress.toLowerCase();
      }
    }
  },[props.kycStatus,props.publicAddress]);


  const veriffStart = async () => {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    const veriff = Veriff({
      host: 'https://stationapi.veriff.com',
      apiKey: process.env.REACT_APP_VERIFF,
      parentId: 'veriff-root',
      onSession: async function (err, response) {
        await waitFor(1500);
        let email = document.getElementById('veriff-given-email').value;
        if (!email) return
        let id = response.verification.id;
        let vendorData = response.verification.vendorData;
        let url = response.verification.url;
        let status = response.verification.status;
        let session = response.verification.sessionToken;
        vendorData = vendorData.toLowerCase();
        if (id && vendorData && url && status && session) {

          props.kycSubmit({ id, email, vendorData, url, status, session, chainId: networkId });
          await waitFor(1500);
          window.location.href = response.verification.url;
        }
      }
    });
    veriff.mount({
      formLabel: {
        givenName: 'First Name',
        lastName: 'Last Name',
        vendorData: 'Metamask Wallet Address'
      },
    });
  }

  const handleWalletChanges = () => {
        connectMetamask(network, networkType)
        .then(async ({address}) => {
          if(address){
              let chain = await web3.eth.getChainId();
              chain = web3.utils.hexToNumber(chain);
              chain = Number(chain);
              props.setAddress(address);
              props.getKycStatus({publicAddress:address});
          }
        })
        .catch((error) => {
          props.loginLoader({ status: false, message: "Verifying kyc..." });
          return EventBus.publish("error", `failed to connect wallet: ${error}`); 
      });
  };

  const generateRandomNonce = (length) =>{
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const nonceArray = new Uint8Array(length);
    window.crypto.getRandomValues(nonceArray);
    let nonce = '';
    for (let i = 0; i < length; i++) {
        nonce += charset[nonceArray[i] % charset.length];
    }
    return nonce;
  };
  
  const submitLogin = async () => {
    try {
      await window.ethereum.enable();
      let netId = await  web3.eth.net.getId();
      if (netId != networkId) {
        EventBus.publish('info', message);
        return;
      }
      const accounts = await web3.eth.getAccounts();
      const publicAddress = accounts[0];
      if(!publicAddress) return EventBus.publish("error", `Please connect metamask!`);
      let nonce = generateRandomNonce(50);
      await web3.eth.personal.sign(`cifi signature ${nonce}`, publicAddress);
      props.loginLoader({ status: true, message: "Logging in..." });
      props.login({ publicAddress });

    } catch (error) {
      EventBus.publish("error", `Login Failed!`);
      console.log("error", error)
      return;
    }
  }

    return (
      <div className="wrapper"  >
        <section class="form-main sign-up sign-in">
          <div class="container">
            <div class="inner text-center">
            {props.kycStatus == false &&
                    <>
                      <h2>Verification</h2>
                      <p className="mb-4 left">Verify your Identity to access the features of Microeconomy.</p>
                      <div id='veriff-root' className="edit-add-body col-12"></div>
                      <div className="veriff-wrap">
                        <div id='veriff-root' className="edit-add-body col-12"></div>
                      </div>
                    </>
                  }

                  {props.kycStatus == "expired" &&
                    <>
                      <h2>Verification</h2>
                      <p className="mb-4 left">Verify your Identity to access the features of Microeconomy.</p>
                      <div className="veriff-wrap">
                        <div id='veriff-root' className="edit-add-body col-12"></div>
                      </div>
                    </>
                  }

                  {props.kycStatus == "created" &&
                    <p className="text-center">Verification Already Submitted, It will take 24Hrs to process your verification.</p>
                  }
                  {props.kycStatus == "approved" &&
                    <p className="text-center">Verification Already Submitted, It will take 24Hrs to process your verification.</p>
                  }
                  {props.kycStatus == "adminApproved" &&
                    <>
                       <h1 class="mb-4">Login to CiFi</h1>
              
                      <p class="mb-5">Please select your wallet to login to your CiFi account</p> 

                      <button class="common-btn d-inline-flex align-items-center px-4" onClick={submitLogin}>
                        <img class="mr-2" src={metamaskicon} />
                        Metamask Login 
                      </button>
                    </>
                  }
            </div>
          </div>
        </section>
      </div>
    );
}

const mapDispatchToProps = { loginLoader, login, getKycStatus, setAddress, getNonce, kycSubmit };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, kycStatus,nonce } = Auth;
  return { publicAddress, kycStatus,nonce };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIn));
