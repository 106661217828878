export const getNonce = (data) => ({
  type: 'GET_NONCE',
  payload: data,
});

export const setNonce = (data) => ({
  type: 'SET_NONCE',
  payload: data,
});


export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
  history: data.history,
});

export const setUser = (data) => ({
  type: 'SET_USER',
  payload: data,
});

export const loginLoader = data => ({
  type: 'LOGIN_LOADER',
  payload: data
});


export const setLoginData = (data) => ({
  type: 'SET_LOGIN_DATA',
  payload: data,
});

export const logout = (data) => ({
  type: 'LOGOUT',
  payload: data,
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data,
});

export const toggleLoader = (data) => ({
  type: 'TOGGLE_LOADER',
  payload: data,
});

export const setMetaData = (data) => ({
  type: 'SET_METADATA',
  payload: data,
});

export const getMetaData = (data) => ({
  type: 'GET_METADATA',
  payload: data,
});

export const getUserMetaData = (data) => ({
  type: 'GET_USER_METADATA',
  payload: data,
});

/*========== KYC ACTIONS ============= */

export const getKycStatus = (data) => ({
  type: 'GET_KYC_STATUS',
  payload: data
});

export const setKycStatus = (data) => ({
  type: 'SET_KYC_STATUS',
  payload: data
});

export const setKyc = (data) => ({
  type: 'SET_KYC',
  payload: data
});

export const kycSubmit = (data) => ({
  type: 'KYC_SUBMIT',
  payload: data
});

export const kycResponse = (data) => ({
  type: 'KYC_RESPONSE',
  payload: data
});