import EventBus from "eventing-bus";
import jwt_decode from 'jwt-decode';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Error from '@material-ui/icons/Error';
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from 'react-toastify';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import Learn2Earn from './Learn2Earn';
import SignIn from './Learn2Earn/sign-in.js';
import ConnectWallet from './Learn2Earn/ConnectWallet.js';
import ConnectingWallet from './Learn2Earn/ConnectingWallet.js';

import { web3 } from "../store/web3";
import Loader from '../components/loader';
import PrivateRoute from '../store/PrivateRoute';
import { networkId, message } from "../store/config";
import { logout, login } from '../store/actions/Auth';

import '../static/css/style.css';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-toastify/dist/ReactToastify.css";

const hist = createBrowserHistory();
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: this.props.isLogin
    }
  };

  verifyTokenExpire = () => {
    const decoded = jwt_decode(this.props.auth);
    const currentDate = new Date();
    const expirationTimestamp = decoded.exp * 1000;
    if (currentDate.getTime() > expirationTimestamp) {
      this.setState({ isAuthenticated: false })
    } else {
      this.setState({ isAuthenticated: true })
    }
  }

  componentDidMount() {
    if (this.props.token) {
      this.verifyTokenExpire()
    }
    this.checkEthereum();
    EventBus.on('tokenExpired', () => this.props.logout());
    EventBus.on('info', (e) => toast.info(() => <div> <Error /> {e}</div>));
    EventBus.on('error', (e) => toast.error(() => <div> <Error /> {e}</div>));
    EventBus.on('success', (e) => toast.success(() => <div> <CheckCircle /> {e}</div>));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.auth !== this.props.auth) {
      if (this.props.auth) {
        this.verifyTokenExpire()
      }
    }
  }

  checkEthereum = async () => {
    if (typeof window.ethereum !== "undefined") {
      // check network
      // web3.eth.net.getId((err, netId) => {
      //   if (netId != networkId) {
      //     EventBus.publish('info', message);
      //   }
      // });

      window.ethereum.on("accountsChanged", accounts => {
        this.props.logout();
        window.location.reload();
        EventBus.publish("info", "Account has been changed");
      });

      window.ethereum.on("networkChanged", netId => {
        this.props.logout();
        window.location.reload();
        EventBus.publish("info", "Network has been changed");
      });
    }
  };

  render() {
    let { isAuthenticated } = this.state;
    return (
      <div>
        <Loader />
        <ToastContainer
          closeOnClick
          position="bottom-left"
        />

        <Router history={hist}>
          <Switch>
            <Route exact path='/' render={props => !isAuthenticated ? <SignIn {...props} /> : <Learn2Earn {...props} />} />
            <Route exact path='/connectwallet' render={props => !isAuthenticated ? <ConnectWallet {...props} /> : <Learn2Earn {...props} />} />
            <Route exact path='/connectingwallet' render={props => !isAuthenticated ? <ConnectingWallet {...props} /> : <Learn2Earn {...props} />} />
            <Route exact path='/Learn2Earn' render={props => !isAuthenticated ? <SignIn {...props} /> : <Learn2Earn {...props} />} />
            <Redirect from="*" to="/" />
          </Switch>
        </Router>

      </div>
    );
  }
}

const mapDispatchToProps = {
  logout, login
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, isLogin, auth } = Auth;
  return { publicAddress, isLogin, auth }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);