const { web3 } = require('../web3');
const { env } = require('../config');

const ERC20Contract = require(`./${env}/ERC20.json`);
const SubscriptionContract = require(`./${env}/Subscription.json`);


const SubscriptionABI = SubscriptionContract['abi'];
const SubscriptionAddress = SubscriptionContract['address'];
const Subscription = new web3.eth.Contract(SubscriptionABI, SubscriptionAddress);

const ERC20ABI = ERC20Contract['abi'];
const ERC20Address = ERC20Contract['address'];
const ERC20 = new web3.eth.Contract(ERC20ABI, ERC20Address);


module.exports = {
  SubscriptionABI,SubscriptionAddress,Subscription,
  ERC20ABI,ERC20Address,ERC20,
  web3
};
