import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { Link, withRouter, NavLink } from 'react-router-dom';
import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';

import './index.css';
import { web3 } from '../../store/web3';
import { networkId, message } from '../../store/config';
import { login, logout } from '../../store/actions/Auth';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            address: '',
            publicAddress: localStorage.getItem('publicAddress')
        };
    };

    logoutUser = () => {
        this.props.logout();
        window.location.reload();
    }
    render() {
        let { address, publicAddress } = this.state;
        let { sticky, auth } = this.props;
        console.log("*** publicAddress", publicAddress);

        return (
            // <div className={`l-earn-nav ${sticky && 'sticky-nav'}`}>
            <header>
                <div class="container">
                    <nav class="navbar navbar-expand-lg">
                        <NavLink class="logo" to="/">
                            <img src="/images/logo.png" alt="" />
                        </NavLink>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <div class="inner">
                                <div class="join">
                                    {
                                        auth ?
                                            <button onClick={() => this.logoutUser()} type="button">Logout</button>
                                            :
                                            <a href="/sign-in">Sign in</a>
                                    }
                                    {/* <button onClick={this.connectWallet} type="button">
                                        {publicAddress == '' || publicAddress == null
                                            ? 'Join for free'
                                            : publicAddress && publicAddress.substring(0, 5) + '.....' + publicAddress.substring(37, publicAddress.length)
                                        }
                                    </button> */}
                                </div>
                            </div>
                        </div>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                            <span class="navbar-toggler-icon"></span>
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </nav>
                </div>
                {/* <nav className='navbar navbar-expand-lg sidenav' id="sidenav-1" data-mdb-hidden="false">
                    <div className='container-fluid'>
                        <div className="inner-container">
                            <button onClick={() => this.setState({ isOpen: true })} className='navbar-toggler' type='button' data-toggle='collapse'
                                data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false'
                                aria-label='Toggle navigation' aria-haspopup="true">
                                <i class='fa fa-bars' aria-hidden='true'></i>
                            </button>
                            <div className='collapse navbar-collapse nav-links' id='navbarSupportedContent'>
                                <ul className='navbar-nav sidenav-menu'>
                                    <button onClick={this.connectWallet} className="nav-btn btn-style-one desktop-btn" type="button">
                                        {publicAddress == '' || publicAddress == null
                                            ? 'Connect wallet'
                                            : publicAddress && publicAddress.substring(0, 5) + '.....' + publicAddress.substring(37, publicAddress.length)
                                        }
                                    </button>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav> */}

                {/* </div > */}
            </header>
        );
    }
}

const mapDispatchToProps = {
    login, logout
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, auth } = Auth;
    return { publicAddress, auth }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));