import { setToken } from '../axios';
import { PURGE } from "redux-persist";


let initialState = {
  publicAddress: '',
  auth: localStorage.getItem('token'),
  isLogin: localStorage.getItem("token") ? true : false,
  userData: localStorage.getItem('user'),
  setLoader: { message: 'Please Wait...', status: false },
  metadata:[],
  nonce: {},
  kycValue: [],
  kycStatus: false,
  kycSubmitted: {},
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case "SET_NONCE":
        return {
          ...state,
          nonce: payload
    };

    case "KYC_RESPONSE":
      return {
        ...state,
        kycSubmitted: payload,
      };

    case 'SET_LOGIN_DATA':
      setToken(payload.token);
      localStorage.setItem(
        'token',
        payload.token
      );
      return {
        ...state,
        auth: payload.token,
        isLogin: true
      };

    case 'LOGIN_LOADER':
      return {
        ...state,
        setLoader: payload
      };

    case 'SET_USER':
      localStorage.setItem("user", JSON.stringify(payload));
      return {
        ...state,
        userData: JSON.stringify(payload)
      };

    case 'LOGOUT':
      localStorage.removeItem('user');
      localStorage.removeItem('publicAddress');
      localStorage.removeItem("token");
      localStorage.removeItem("kycStatus");
      return {
        ...state,
        publicAddress: '',
        isLogin: false,
        auth: "",
        userStatus:{}
      };

    case 'TOGGLE_LOADER':
      return {
        ...state,
        setLoader: payload,
      };

    case "SET_ADDRESS":
      localStorage.setItem("publicAddress", payload);
      return {
        ...state,
        publicAddress: payload,
      };

    /*========== ASSESSMENT REDUCERS ============= */
      case "GET_METADATA":
        return {
          ...state,
          metadata: payload,
        };

     case "SET_KYC":
      return {
        ...state,
        kycValue: payload,
      };

    case "SET_KYC_STATUS":
      return {
        ...state,
        kycStatus: payload,
      };

    default:
      return state;
  }
};

export default Auth;