import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";

import "./index.css";
import "../../static/css/animate.css";

import { web3 } from "../../store/web3";
import { networkId, message } from "../../store/config";
import { toggleLoader } from "../../store/actions/Auth";
import { UserSoulBoundABI, UserSoulBoundBytecode } from '../../store/contract';
import { Link, withRouter } from 'react-router-dom';
const metamask1 = "/images/metamask1.png";
const metamask2 = "/images/metamask2.png";
const metamask3 = "/images/metamask3.png";
const metamask4 = "/images/metamask4.png";
const metamask5 = "/images/metamask5.png";
const metamask6 = "/images/metamask6.png";

class ConnectWallet extends Component {
  constructor(props) {
    super(props);
  
  }

  render() {
    return (
      <div className="wrapper"  >
        {/* <Navbar sticky={sticky} /> */}
        <section class="form-main sign-up">
          <div class="container">
            <div class="inner">
              <h1>Connect your wallet</h1>

              <p class="mb-4">Please select your wallet to login</p>
              
              <div class="connect">
                <img src={metamask1} />

                <div class="">
                    <h3>Metamask</h3>
                </div>
              </div>

              <div class="connect">
                <img src={metamask2} />

                <div class="">
                    <h3>Metamask</h3>
                </div>
              </div>

              <div class="connect">
                <img src={metamask3} />

                <div class="">
                    <h3>Metamask</h3>
                </div>
              </div>

              <div class="connect">
                <img src={metamask4} />

                <div class="">
                    <h3>Metamask</h3>
                </div>
              </div>

              <div class="connect">
                <img src={metamask5} />

                <div class="">
                    <h3>Metamask</h3>
                </div>
              </div>

              <div class="connect">
                <img src={metamask6} />

                <div class="">
                    <h3>Metamask</h3>
                </div>
              </div>

              <div class="bottom">
                <Link to="">New to Wallets?</Link>

                <p><Link to="">Get Started</Link></p>
              </div>
            </div>
          </div>
        </section>

        {/* <Footer /> */}
      </div>
    );
  }
}

const mapDispatchToProps = { toggleLoader};

const mapStateToProps = ({ Auth }) => {
  let { } = Auth;
  return { };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConnectWallet));
