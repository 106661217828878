import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, {useEffect ,useState } from "react";
import { Link, withRouter } from 'react-router-dom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import "./index.css";
import "../../static/css/animate.css";

import Navbar from "../../components/navbar";
import { toggleLoader, setMetaData, getUserMetaData } from "../../store/actions/Auth";
import { ERC20,ERC20Address,Subscription,SubscriptionAddress, web3} from "../../store/contract";
import { CreateIPFS, CreateIPFSMetadata, TokenValidate} from "../../store/ipfs"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { helpers } from "chart.js";

const metamask1 = "/images/metamask1.png";
const copyicon = "/images/document-copy.png";
const uploadimg = "/images/camera.png";

 function Learn2Earn(props) {
    let [metadataDetailModal, setMetadataDetailModal] = useState(false);
    
    let [submitMetatdata, setSubmitMetadata] = useState({
      collectionName:"",
      collectionDescription:"",
      collectionSize:0,
      collectionImage:"",
      PINATA_API_KEY:"",
      PINATA_API_SECRET:"",
      authorization:"",
    });

    useEffect(()=>{
      props.toggleLoader({message:"Load user metadata...",status:true});
      props.getUserMetaData();
    },[])

    useEffect(()=>{
      if(props.metadata){
        setMetadataDetailModal(false);
        setSubmitMetadata({
        collectionName:"",
        collectionDescription:"",
        collectionSize:0,
        collectionImage:"",
        PINATA_API_KEY:"",
        PINATA_API_SECRET:"",
        authorization:"",})
      }
    },[props.metadata])

    const handelMetadata = async (e) => {
      let key;
      let value;
      if(e.target.files){
        key = e.target.name
        value = e.target.files[0];
      }else{
        key = e.target.name
        value = e.target.value;
      }
      setSubmitMetadata({...submitMetatdata,[key]:value})
    }

    const handelUploadMeatdata = async () => {
      try {

        let {collectionName,collectionDescription,collectionSize,collectionImage,PINATA_API_KEY,PINATA_API_SECRET,authorization} = submitMetatdata;
        if(!collectionName) return EventBus.publish("error", "Please enter name");

        if (!collectionName.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter name`);
          return;
        }
  
        if (!collectionName.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `Name must contain alphabets`);
          return;
        }

        if(!collectionDescription) return EventBus.publish("error", "Please enter description");


        if (!collectionDescription.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter description`);
          return;
        }
  
        if (!collectionDescription.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `Description must contain alphabets`);
          return;
        }

        if(collectionSize == 0) return EventBus.publish("error", "Please enter size");
        if(!collectionImage) return EventBus.publish("error", "Please upload image");

        if (collectionImage == undefined) {
          EventBus.publish("error", `Please upload image`);
          return;
        }

        if(!PINATA_API_KEY) return EventBus.publish("error", "Please enter Pinata API Key");
        if(!PINATA_API_SECRET) return EventBus.publish("error", "Please enter Pinata API Secret");
        if(!authorization) return EventBus.publish("error", "Please enter Pinata API Auth Token");
        
        await TokenValidate(PINATA_API_KEY,PINATA_API_SECRET,authorization);

        let publicAddress = await web3.eth.getAccounts();
        publicAddress = publicAddress[0];

        let price = await Subscription.methods.subscriptionPrice().call();
        let balanceOf = await ERC20.methods.balanceOf(publicAddress).call();

        if(parseInt(balanceOf) < parseInt(price)) return EventBus.publish("error", `Insufficient CIFI Tokens!`); 
        props.toggleLoader({message:"Approve Transaction...",status:true});
        await web3.eth
            .sendTransaction({
              from: publicAddress,
              value: 0,
              to: ERC20Address,
              gas: 2000000,
              data: ERC20.methods
                .approve(SubscriptionAddress,price)
                .encodeABI(),
            })
            .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
            .on("receipt", async (receipt) => {
              props.toggleLoader({message:"Transaction Confirm...",status:true});
              await web3.eth
                .sendTransaction({
                  from: publicAddress,
                  value: 0,
                  to: SubscriptionAddress,
                  gas: 2000000,
                  data: Subscription.methods
                    .subscribeNow()
                    .encodeABI(),
                })
                .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
                .on("receipt", async (receipt) => {
                
                  props.toggleLoader({message:"Metadata Generating...",status:true});

                  let uploadImage = await CreateIPFS(collectionImage,PINATA_API_KEY,PINATA_API_SECRET,authorization);
                  if(uploadImage){
                      collectionSize = parseInt(collectionSize);
                      let nameCollection = collectionName.replaceAll(" ","_");
                      props.toggleLoader({message:"Metadata Uploading...",status:true});
                      let formData = new FormData();
                      formData.append('pinataMetadata',JSON.stringify({ name: nameCollection }));
                      for (let file = 0; file < collectionSize; file++) {
                        const fileData = {
                          "name": `${collectionName} #${file + 1}`,
                          "description": collectionDescription,
                          "image": uploadImage
                        };
                        const fileBlob = new Blob([JSON.stringify(fileData)], { type: 'application/json' });
                        formData.append('file', fileBlob, `${nameCollection}/${file + 1}.json`);
                      }
          
                    props.toggleLoader({message:"Metadata Finalizing...",status:true});
                    let {baseURI,hash} = await CreateIPFSMetadata(formData,PINATA_API_KEY,PINATA_API_SECRET,authorization);
                    props.setMetaData({image:uploadImage,name:collectionName,description:collectionDescription,size:collectionSize,matadataHash:hash,baseURI})
                  }

              });
        });

      } catch (error) {
          props.toggleLoader({message:"Metadata Finalizing...",status:false});
          return EventBus.publish("error", error.message);
      }
    }

    const copyHash = () => {return EventBus.publish("success", "Hash copied");}
    const copyBaseUri = () => {return EventBus.publish("success", "BaseUri copied");}

    return (
      <div className="wrapper"  >
        <Navbar />

        <div class="nft-generator">
          <div class="container">
            <div className="head">
              <div>
                <h2>NFT Metadata Generator</h2>

                <p>Generate your entire NFT Metadata with our NFT Metadata Generator.</p>
              </div>

              <button type="button" class="common-btn" onClick={()=>setMetadataDetailModal(true)}>Generate Metadata</button>
            </div>
            
            <div class="table-wrap">
              <table>
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Hash</th>
                    <th>Collection name</th>
                    <th>Description</th>
                    <th>Base Uri</th>
                    <th>Size</th>
                  </tr>
                </thead>
                
                <tbody>
                  {props.metadata && props.metadata.length > 0 && props.metadata.map(item=>
                  <tr>
                    <td><img src={item.image} /></td>
                    <td>
                    <CopyToClipboard text={item.matadataHash}
                      onCopy={() => copyHash()}>
                      <div class="address">
                       {item.matadataHash.length <= 6 * 2 ? item.matadataHash : item.matadataHash.substring(0, 6) + '...' + item.matadataHash.substring(item.matadataHash.length - 6)}
                        <img src={copyicon} />
                      </div>
                    </CopyToClipboard>
                    
                    </td>
                    <td> {item.name}</td>
                    <td>
                     {item.description.length <= 50 ? item.description : item.description.substring(0, 50) + '...' + item.description.substring(item.description.length - 50)}  
                    </td>
                    <td>
                    <CopyToClipboard text={`${item.baseURI}/`}
                      onCopy={() => copyBaseUri()}>
                      <div class="address">
                       {item.baseURI.length <= 6 * 2 ? item.baseURI : item.baseURI.substring(0, 6) + '...' + item.baseURI.substring(item.baseURI.length - 6)}
                        <img src={copyicon} />
                      </div>
                    </CopyToClipboard>
                    </td>
                    <td>{item.size}</td>
                  </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Modal
        open={metadataDetailModal}
        onClose={() => setMetadataDetailModal(false)}
        classNames={{
          modal: `common-modal course-detail-modal`
        }}
        center
      >
        <div class="modal-content">
          <h2 className="text-center">Create an NFT Metadata Base URI</h2>
          <div class="modal-header">
            <button type="button" className="close" onClick={() => setMetadataDetailModal(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="form-main">
              <div class="inner">
                {/* <form> */}
                  <div className="row">
                    <div class="form-group col-12">
                      <input type="text" name="collectionName" class="form-control" placeholder="Collection Name" onChange={handelMetadata} />
                    </div>

                    <div class="form-group col-12">
                      <textarea  class="form-control"  name="collectionDescription" placeholder="Description" onChange={handelMetadata}/>
                    </div>
                  </div>

                  <div className="row">
                    <div class="form-group col-12">
                      <input type="number" class="form-control" name="collectionSize" placeholder="Collection Size*" onChange={handelMetadata}/>
                    </div>

                    <div class="form-group col-12">
                        <div className="file-wrap">
                            <input type="file" accept="image/*" name="collectionImage" class="form-control" placeholder="Select Image" onChange={handelMetadata}/>
                            <span>{submitMetatdata.collectionImage ? submitMetatdata.collectionImage.name.substring(0, 6) + '...' + submitMetatdata.collectionImage.name.substring(submitMetatdata.collectionImage.name.length - 6) :"Select Image" }</span>
                            <img src={uploadimg} />
                        </div>
                    </div>

                    <div class="form-group col-12">
                      <input type="text" class="form-control" name="PINATA_API_KEY" placeholder="Pinata API Key" onChange={handelMetadata}/>
                    </div>

                    <div class="form-group col-12">
                      <input type="text" class="form-control" name="PINATA_API_SECRET" placeholder="Pinata API Secret" onChange={handelMetadata}/>
                    </div>

                    <div class="form-group col-12">
                      <input type="text" class="form-control" name="authorization" placeholder="Pinata Auth Token" onChange={handelMetadata}/>
                    </div>
                  </div>

                  <div class="bottom d-flex justify-content-between w-100 mt-4 mx-3">
                    <button class="back common-btn" onClick={() => setMetadataDetailModal(false)}>Cancel</button>

                    <button class="common-btn" onClick={()=>handelUploadMeatdata()}>Create</button>
                  </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      </div>
    );
  }

const mapDispatchToProps = { toggleLoader,getUserMetaData, setMetaData };

const mapStateToProps = ({ Auth }) => {
  let { metadata } = Auth;
  return { metadata };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Learn2Earn));
