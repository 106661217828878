import axios  from "axios";
export const CreateIPFS = (fileImg, apiKey, apiSecret, authToken) => {
    return new Promise(async (resolve, reject)=>{
       try {
            const formData = new FormData();
            formData.append("file", fileImg);
            const resFile = await axios({
                method: "post",
                url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
                data: formData,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'pinata_api_key': apiKey,
                    'pinata_secret_api_key': apiSecret,
                    "Content-Type": "multipart/form-data"
                },
            });
            const ImgHash = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`;
            return resolve(ImgHash);
       } catch (error) {
        return reject(error);
       }
    })
};

export const CreateIPFSMetadata = (formData, apiKey, apiSecret, authToken) => {
  return new Promise(async (resolve, reject)=>{
    try {
        var config = {
            method: 'post',
            url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
            'maxBodyLength': 'Infinity',
            headers: {
              'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
              'authorization': `Bearer ${authToken}`,
              'pinata_api_key': apiKey,
              'pinata_secret_api_key': apiSecret,
            },
            data: formData
          };
          
            let response =  await axios(config)
      
             console.log('Folder pinned to Pinata!');
             console.log('Pinata IPFS Hash:', response.data.IpfsHash);
            const baseURI = `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`;
            return resolve({baseURI, hash:response.data.IpfsHash});
    } catch (error) {
      return reject(error);
    }
  })
};

export const TokenValidate = (apiKey, apiSecret, authToken) => {
  return new Promise(async (resolve, reject)=>{
     try {
          const resFile = await axios({
              method: "get",
              url: "https://api.pinata.cloud/data/testAuthentication",
              headers: {
                  'Authorization': `Bearer ${authToken}`,
                  'pinata_api_key': apiKey,
                  'pinata_secret_api_key': apiSecret,
              },
          });
          return resolve(resFile);
     } catch (error) {
         return reject(error);
     }
  })
};
