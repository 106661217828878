import axios from 'axios';
import { web3 } from '../web3';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { setHeader, setToken } from "../axios";
import { all, takeEvery, call, put } from 'redux-saga/effects';

import { setAddress, loginLoader,kycResponse,toggleLoader, setUser, setLoginData, getMetaData, setNonce, setKycStatus, setKyc} from '../actions/Auth';

/*========== ASSESSMENT FUNCTIONS =============*/


export function* login({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/loginUser', payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    const decoded = jwt_decode(response['data']['body']['token']);
    yield put(
      setUser(decoded)
    );
    if (!['user'].includes(decoded['role'])) {
      EventBus.publish('error', "Please login through user account!");
      return;
    }
    setToken(response['data']['body']['token']);
    yield put(
      setLoginData({ token: response['data']['body']['token'] })
    );
    yield put({type:"GET_RESULT",payload:{ _id: decoded['userId'] }});
    EventBus.publish('success', response['data']['message']);

    // setTimeout(() => history.push(`/`), 1000);
    
    // EventBus.publish("success",response['data']['message']);
  }
  yield put(loginLoader({ status: false }));
}

export function* getNonce({ payload }) {
  const { error, response } = yield call(getCall, `/getNonce/${payload}`);
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setNonce(response["data"]["body"]));
  }
};

export function* kycSubmit({ payload }) {
  const { error, response } = yield call(postCall, { path: '/kycSubmit', payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  } else if (response) {
    yield put(kycResponse(response['data']['body']));
  }
  yield put(loginLoader({ status: false }));
};

export function* getKycStatus({ payload }) {
  const { error, response } = yield call(postCall, { path: '/getKycStatus', payload });
  if (error) {
    localStorage.setItem("kycStatus", "nill");
    yield put(loginLoader({ status: false }));
    // EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    localStorage.setItem("kycStatus", response['data']['body']['status']);
    yield put(setKyc(response['data']['body']));
    yield put(setKycStatus(response['data']['body']['status']));
    yield put(loginLoader({ status: false }));
  }
};

export function* logout() {
  yield put(setLoginData({ token: "" }));
  yield put(setAddress(""));
};


export function* setMetaData({ payload }) {
  const { error, response } = yield call(postCall, { path: "/updateMetadata", payload });
  if (error) {
    yield put(loginLoader({ status: false }));
    EventBus.publish("error", error['response']['data']['message']); 
  }
  else if (response) {
    yield put(loginLoader({ status: false }));
    yield put(getMetaData(response['data']['body']));
    EventBus.publish("success", response['data']['message']);
  }
};

export function* getUserMetaData({ payload }) {
  const { error, response } = yield call(getCall,"/getMeatadata");
  if (error) { 
    yield put(loginLoader({ status: false }));
    // EventBus.publish("error", error['response']['data']['message']); 
  }
  else if (response) {
    yield put(getMetaData(response['data']['body']));
    yield put(loginLoader({ status: false }));
    // EventBus.publish("success",response['data']['message']);
  }
};


function* actionWatcher() {
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
